import { getFormProps } from '@conform-to/react';
import { Outlet } from '@remix-run/react';
import { TypedFetcherWithComponents } from 'remix-typedjson';

export default function UCFetcherForm<T>({
  formProps,
  method,
  fetcher,
  className,
  encType,
  children,
}: {
  formProps: ReturnType<typeof getFormProps>;
  method: 'post' | 'get' | 'put' | 'delete';
  fetcher: TypedFetcherWithComponents<T>;
  className?: string;
  encType?:
    | 'application/x-www-form-urlencoded'
    | 'multipart/form-data'
    | 'text/plain';
  children?: React.ReactNode;
}) {
  return (
    <fetcher.Form
      // Enterキー入力で発生する意図しないフォーム送信を防ぐ
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
      {...formProps}
      method={method}
      className={className}
      encType={encType}
    >
      {children ? children : <Outlet />}
    </fetcher.Form>
  );
}
